import React,  { useState }  from 'react';
import CartNotification from 'components/navbar/top/CartNotification';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Button, Nav } from 'react-bootstrap';
import NineDotMenu from './NineDotMenu';
import ThemeControlDropdown from './ThemeControlDropdown';
import { Link } from 'react-router-dom';
import { ApiCall } from '../../../includes/Webservice';
import { BaseUrl  } from '../../../includes/Helper'; 
import { FaHandPointLeft, FaHandPointRight } from "react-icons/fa";

const TopNavRightSideNavItem = () => {
  const baseurl = BaseUrl();
  const [button_enable, setButtonEnable] = useState(false);
  const controller = new AbortController();
  React.useEffect(() => {
     // getSetting(); 
  }, [])


  const getSetting = async () => {
    
     const data = {setting_type : 'Quick_Report_Button'};
     const result = await ApiCall(baseurl + "/setting/type", "post",  1,0, 0, data, '', controller);
     if (result) { 
        if (result.status == true) {  
           if(result.data.setting_value == 1)
             setButtonEnable(true) 
           else
           setButtonEnable(false) 
        }  
      }  
  }

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
       
       <p className='me-2 mt-2 d-none d-md-block blink_me fs-10 text-danger'>Click here to generate radiology report at lightning speed 
       <FaHandPointRight className='ms-2 fs-8' />
        </p><Link to="/report/quick"><Button className='btn btn-sm btn-danger me-3 ' >Generate Quick Report</Button></Link>
      
      <ThemeControlDropdown />
      {/*} <NotificationDropdown />
        <NineDotMenu /> {*/}
      <ProfileDropdown />
    </Nav>
  );
};

/*
{button_enable &&
       <Link to="/report/quick"><Button className='btn btn-danger me-3 blink_me' >Generate Quick Report</Button></Link>
      }
*/

export default TopNavRightSideNavItem;
